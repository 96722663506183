import Api from "@/config/Api";

export default {
  getDatafastTokens() {
    return Api().get("/me/datafast-tokens", {});
  },
  updateDatafastTokensId(token) {
    return Api().put(`/me/datafast-tokens/${token.id}`, token);
  },

  deleteDatafastTokensId(token) {
    return Api().delete(`/me/datafast-tokens/${token}`, {});
  }
};
