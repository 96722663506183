<template>
  <div>
    <TopBarTitleComponent title="Perfil usuario" :showBackIcon="true">
      <template slot="right-area">
        <div class="d-flex justify-content-between float-right"></div>
      </template>
    </TopBarTitleComponent>
    <section class="">
      <b-container fluid>
        <b-row>
          <b-col sm="12" md="12" lg="12">
            <div class="box-shadow border-radius p-3 mb-3 bg-color-write">
              <el-tabs v-model="activeTabName">
                <el-tab-pane
                  label="Información básica"
                  name="informations"
                  class="p-4"
                >
                  <ProfilePersonalInfo />
                </el-tab-pane>
                <el-tab-pane
                  label="Cambiar contraseña"
                  name="resetPassword"
                  class="p-4"
                >
                  <ProfilePersonalPasswordChange />
                </el-tab-pane>
                <el-tab-pane label="Tarjetas" name="creditCards" class="p-4">
                  <DatafastTokensHistory />
                </el-tab-pane>
              </el-tabs></div></b-col></b-row
      ></b-container>
    </section></div
></template>

<script>
import TopBarTitleComponent from "../../../../components/TopBarTitle";
import ProfilePersonalInfo from "../components/InfoProfile";
import ProfilePersonalPasswordChange from "../components/PasswordChange";
import DatafastTokensHistory from "../components/DatafastTokensHistory";

export default {
  name: "ProfilePersonalPage",
  data: () => ({
    activeTabName: "informations"
  }),
  computed: {},
  methods: {},
  components: {
    TopBarTitleComponent,
    ProfilePersonalInfo,
    ProfilePersonalPasswordChange,
    DatafastTokensHistory
  },

  mounted() {}
};
</script>

<style scoped lang="scss"></style>
