<template>
  <div class="">
    <b-row>
      <b-col sm="12" md="12" lg="12">
        <div class="image" :style="[user.image ? styleProfileImage : '']">
          <FileUploadComponent
            title="Selecciona imagen perfil"
            @fileBase64="file => onFileBase64UserImage(file)"
            :preview="user.image"
            className="image-profile mt-4"
            :showFileList="false"
          />
        </div>
      </b-col>
    </b-row>
    <h4 class="h4 font-semi-bold mt-3 mb-4">Datos personales</h4>
    <b-row>
      <b-col sm="12" md="6" lg="6">
        <b-row>
          <b-col sm="12">
            <span class="font-semi-bold mb-0">Nombre</span>
            <input
              type="text"
              class="form-control input-profile mb-3"
              v-model="user.name"
            />
          </b-col>
          <b-col sm="12">
            <span class="font-semi-bold mb-0">Teléfono</span>
            <input
              type="text"
              class="form-control input-profile mb-3"
              v-model="user.phone"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="12" md="6" lg="6">
        <b-row>
          <b-col sm="12">
            <span class="font-semi-bold mb-0">Apellido</span>
            <input
              type="text"
              class="form-control input-profile mb-3"
              v-model="user.last_name"
            />
          </b-col>
          <b-col sm="12">
            <span class="font-semi-bold mb-0">
              Correo
              <el-popover
                placement="top-start"
                width="200"
                trigger="hover"
                content="Este campo no se puede editar"
              >
                <i slot="reference" class="el-icon-info el-text-success"></i
              ></el-popover>
            </span>
            <p
              class="form-control input-profile text-overflow-wrap"
              style="box-shadow: none !important;"
            >
              {{ user.email }}
            </p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="mt-4">
      <b-col>
        <ButtonSaveComponent @onSaveClick="handleSaveProfile" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import FileUploadComponent from "../../../../components/FileUpload";
import ButtonSaveComponent from "../../../../components/ButtonSave";

import { bgProfile } from "@/services/resources";

export default {
  name: "ProfilePersonalInfo",
  data: () => ({
    passwordChange: false,
    user: ""
  }),
  computed: {
    getUser() {
      return this.$store.getters.getUser;
    },
    styleProfileImage() {
      return {
        background: `url(${bgProfile}) no-repeat center center`,
        backgroundSize: "cover",
        position: "relative",
        height: "140px"
      };
    }
  },
  methods: {
    onFileBase64UserImage(file) {
      this.user.image = file;
    },
    handlePasswordChange() {
      this.passwordChange = !this.passwordChange;
    },
    async handleSaveProfile() {
      const { name, last_name, phone, image } = this.user;
      const {
        business_name,
        business_ruc,
        business_phone,
        business_email,
        business_address,
        business_logo,
        people_number,
        category_id,
        city_id
      } = this.user.user_business;
      const data = {
        name,
        last_name,
        phone,
        image,
        business_name,
        business_ruc,
        business_phone,
        business_email,
        business_address,
        business_logo,
        people_number,
        category_id,
        city_id
      };
      try {
        let response = await this.$store.dispatch("profileUpdate", data);
        const { success, message } = response.data;
        if (success) {
          this.user = this.getUser;
          this.$notifications.success({
            message
          });
        }
      } catch (error) {
        return false;
      }
    }
  },
  components: {
    FileUploadComponent,
    ButtonSaveComponent
  },
  beforeMount() {
    this.user = this.getUser;
  },

  mounted() {}
};
</script>

<style scoped lang="scss">
.image {
  display: flex !important;
  justify-content: center;
  align-items: baseline;
  height: 100%;
}
#image-profile {
  width: 100px;
}
#image-business {
  width: 100%;
  height: auto;
}
</style>
