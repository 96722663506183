<template>
  <div class="">
    <b-row>
      <b-col sm="12">
        <el-form
          :model="passwordChange"
          :rules="rules"
          ref="formPasswordChange"
        >
          <h4 class="h4 font-bold mb-4">Cambiar contraseña</h4>
          <b-row>
            <b-col xs="12" sm="6">
              <el-form-item prop="password">
                <slot name="label">
                  <label class="font-semi-bold m-0">Nueva Contraseña</label>
                </slot>
                <el-input
                  type="password"
                  v-model="passwordChange.password"
                  show-password
                ></el-input>
              </el-form-item>
            </b-col>
            <b-col xs="12" sm="6">
              <el-form-item prop="passwordConfirm">
                <slot name="label">
                  <label class="font-semi-bold m-0">Confirmar Contraseña</label>
                </slot>
                <el-input
                  type="password"
                  v-model="passwordChange.passwordConfirm"
                  show-password
                ></el-input>
              </el-form-item>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <ButtonSaveComponent @onSaveClick="submitForm" class="mt-4" />
            </b-col>
          </b-row>
        </el-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AuthServices from "@/modules/auth/services/AuthServices";
import ButtonSaveComponent from "../../../../components/ButtonSave";
import Validate from "@/assets/validate";

export default {
  name: "ProfilePersonalPasswordChange",
  data() {
    return {
      passwordChange: {
        password: "",
        passwordConfirm: ""
      },

      rules: {
        password: [
          {
            validator: Validate.rules.compare.password.bind(
              this,
              "formPasswordChange"
            ),
            trigger: "blur"
          }
        ],
        passwordConfirm: [
          {
            validator: Validate.rules.compare.confirmPassword.bind(this),
            trigger: "blur"
          }
        ]
      }
    };
  },

  methods: {
    submitForm() {
      this.$refs.formPasswordChange.validate(async valid => {
        if (valid) {
          this.$store.dispatch("toggleRequestLoading");
          try {
            let response = await AuthServices.passwordChange({
              password: this.passwordChange.password
            }).finally(() => this.$store.dispatch("toggleRequestLoading"));

            const { success, message } = response.data;
            if (success) {
              this.$refs.formPasswordChange.resetFields();
              this.$notifications.success({
                message
              });
            }
          } catch (error) {
            return false;
          }
        }
      });
    }
  },
  computed: {},
  components: {
    ButtonSaveComponent
  }
};
</script>

<style scoped lang="scss">
.rounded-lg {
  background-color: #ddd;
}
input {
  margin-top: -10px;
}
</style>
