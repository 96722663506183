<template>
  <div class="">
    <h4 class="h4 font-bold mb-4">Mis Tarjetas</h4>
    <b-container fluid class="pb-4 px-lg-4">
      <template v-if="apiResponse.success">
        <template v-if="resultTokens.length">
          <section class="table-items table-items-token-history">
            <b-row class="table-items-header line-bottom">
              <b-col xs="12" sm="12" md="2">
                <h6 class="h6">Tarjeta</h6>
              </b-col>
              <b-col xs="12" sm="12" md="2">
                <h6 class="h6">Nombre</h6>
              </b-col>
              <b-col xs="12" sm="12" md="2">
                <h6 class="h6">Número</h6>
              </b-col>
              <b-col xs="12" sm="12" md="1">
                <h6 class="h6">Expira</h6>
              </b-col>
              <b-col xs="12" sm="12" md="2">
                <h6 class="h6">Registrada</h6>
              </b-col>
              <b-col xs="12" sm="12" md="2">
                <h6 class="h6">
                  Estatus
                  <MoreInfoComponent
                    content="Controla tus tarjetas habilitando su uso para pagos automáticos(recurrentes) dentro de la plataforma"
                  />
                </h6>
              </b-col>
              <b-col xs="12" sm="12" md="1"> </b-col>
            </b-row>

            <paginate
              class="wrapper-pagination"
              name="resultTokens"
              :list="resultTokens"
              :per="perPaginate"
            >
              <div
                class="card table-items-body"
                v-for="(token, index) in paginated('resultTokens')"
                :key="index"
              >
                <div class="card-body table-items-content">
                  <b-row class="">
                    <b-col xs="12" sm="12" md="2" class="table-col-item">
                      <p
                        class="item-col-detail font-semi-bold text-color-primary"
                      >
                        {{ token.card_brand }}
                      </p>
                    </b-col>
                    <b-col xs="12" sm="12" md="2" class="table-col-item">
                      <p class="item-col-detail font-semi-bold">
                        {{ token.card_holder }}
                      </p>
                    </b-col>
                    <b-col xs="12" sm="12" md="2" class="table-col-item">
                      <p class="item-col-detail font-bold">
                        {{ tokenCardNumberFormat(token) }}
                      </p>
                    </b-col>
                    <b-col xs="12" sm="12" md="1" class="table-col-item">
                      <p class="font-semi-bold">
                        {{
                          `${token.card_expiry_month}/${token.card_expiry_year}`
                        }}
                      </p>
                    </b-col>
                    <b-col xs="12" sm="12" md="2" class="table-col-item">
                      <p class="font-semi-bold">{{ token.created_at }}</p>
                    </b-col>
                    <b-col xs="12" sm="12" md="2" class="table-col-item">
                      <SwitchComponent
                        :setValue.sync="token.card_status"
                        @onChange="v => onSwitchChange(v, token)"
                      />
                    </b-col>
                    <b-col xs="12" sm="12" md="1" class="table-col-item">
                      <ButtonDeleteComponent
                        :isShowButton="false"
                        @onConfirmDelete="onDeleteToken(index, token)"
                      />
                    </b-col>
                  </b-row>
                </div>
              </div>

              <p class="text-color-black mt-4">
                <small class="font-bold"
                  >IMPORTANTE: {{ appName }} Solo guarda información parcial de
                  referencia de tus tarjetas.</small
                >
              </p>
            </paginate>
            <paginate-links
              v-show="resultTokens.length > perPaginate"
              for="resultTokens"
              :classes="{ ul: 'pagination', li: 'page-item', a: 'page-link' }"
              :simple="{
                next: 'Siguiente »',
                prev: '« Regresar'
              }"
            ></paginate-links>
          </section>
        </template>
        <EmptyData v-else />
      </template>
      <LoadingSkeletonTable :showFilter="false" v-else />
    </b-container>
  </div>
</template>

<script>
import LoadingSkeletonTable from "@/components/Animations/Loading/SkeletonTable";
import SwitchComponent from "../../../../components/Switch";
import ButtonDeleteComponent from "../../../../components/ButtonDelete";
import MoreInfoComponent from "../../../../components/MoreInfo";
import EmptyData from "@/components/General/EmptyData";

import personalService from "../../../services/personalService";

export default {
  name: "DatafastTokensHistory",
  data: () => ({
    apiResponse: {
      success: false
    },
    perPaginate: 10,
    paginate: ["resultTokens"],
    tokens: []
  }),
  computed: {
    resultTokens() {
      return this.tokens;
    },
    appName() {
      return process.env.VUE_APP_NAME;
    }
  },
  methods: {
    toggleApiResponse(property, value) {
      this.apiResponse[property] = value;
    },
    tokenCardNumberFormat(token) {
      const { card_bin, card_last_four_digits } = token;
      return (
        card_bin &&
        card_last_four_digits &&
        `${card_bin}-********-${card_last_four_digits}`
      );
    },
    onSwitchChange({ value: card_status }, _token) {
      const token = { ..._token, card_status };
      this.updateDatafastTokensId(token);
    },
    async onDeleteToken(index, { id: tokenId }) {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await personalService
          .deleteDatafastTokensId(tokenId)
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, message } = response.data;
        if (success) {
          this.$notifications.success({
            message
          });
          this.tokens.splice(index, 1);
        }
      } catch (error) {
        return false;
      }
    },
    async updateDatafastTokensId(token) {
      try {
        this.$store.dispatch("toggleRequestLoading");

        let response = await personalService
          .updateDatafastTokensId(token)
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, message } = response.data;
        if (success) {
          this.$notifications.success({
            message
          });
        }
      } catch (error) {
        return false;
      }
    },
    async getDatafastTokens() {
      try {
        let response = await personalService.getDatafastTokens();
        const { success, data } = response.data;
        if (success) {
          this.tokens = data;
          this.toggleApiResponse("success", true);
        }
      } catch (error) {
        return false;
      }
    }
  },
  components: {
    LoadingSkeletonTable,
    SwitchComponent,
    ButtonDeleteComponent,
    MoreInfoComponent,
    EmptyData
  },
  mounted() {
    this.getDatafastTokens();
  }
};
</script>

<style lang="scss" scoped></style>
